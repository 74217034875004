import React from 'react'
import '../../App.css'

function About() {
  return (
    <div>
      <h1 className='about'>ABOUT</h1>
    </div>
  )
}

export default About
