import React from 'react'
import '../../App.css'

function Projects() {
  return (
    <div>
      <h1 className='projects'>PROJECTS</h1>
    </div>
  )
}

export default Projects
